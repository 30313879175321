import { environment } from './environments/environment';
//import 'core-js/es6';
import 'reflect-metadata';
require('zone.js/dist/zone');

if (environment.production === true) {
  // Production
} else {
  // Development
  Error['stackTraceLimit'] = Infinity;
  require('zone.js/dist/long-stack-trace-zone');
}
// Add global to window, assigning the value of window itself.
(window as any).global = window;
